// Resolve shop online status from ENV configuration
export const shopIsOnline = function(){
  switch(process.env.GATSBY_SHOP_STATUS){
    case 'online':
      return true;
    case 'launch-date':
      const timestamp = Date.parse(process.env.GATSBY_SHOP_LAUNCH_DATE);
      if (isNaN(timestamp)) {
        return false;
      } else {
        return Date.now() > timestamp;
      }
    default:
      return false;
  }
}