import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  fluid: {
    flex: '1 1 auto',
  },
  nav: {
    display: "flex",
    justifyContent: "flex-start",
    position: "sticky",
    top: 0,
    zIndex: 4,
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-end",
    },
  },
  page: {
    position: 'relative',
    zIndex: 0,
  },
}));