// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children, wrapperProps }) => {
    const WrapperComponent = wrapper;

    return condition ? (
        <WrapperComponent {...wrapperProps}>
            {children}
        </WrapperComponent>
    ) : (
        <>
            {children}
        </>
    )
}

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool,
    wrapper: PropTypes.elementType,
    children: PropTypes.node,
    wrapperProps: PropTypes.object,
}

ConditionalWrapper.defaultProps = {
    condition: PropTypes.false,
    wrapper: React.Fragment,
    wrapperProps: {},
}

export default ConditionalWrapper;