import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        display: 'block',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[600],
        fontSize: theme.typography.fontSize - 4 + 'px',
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    sitemap: {
        width: theme.breakpoints.values['md'],
        maxWidth: '100%',
        margin: 'auto',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',

        '& > ul': {
            display: 'contents',
        },

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    sitemapLink: {
        position: 'relative',
        padding: theme.spacing(0.5, 4),
        lineHeight: 1.3,
        textTransform: 'uppercase',

        '& > li': {
            margin: 0,
            padding: 0,
        }
    },
    hr: {
        width: 40,
        maxWidth: '100%',
        border: '1px solid rgba(0,0,0,0.1)',
        margin: theme.spacing(2, 'auto'),
    },
    copyright: {
        display: 'block',
        color: theme.palette.grey[400],
    },
}))