import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Logo = (props) => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "iw-logo.png" }) {
        publicURL
      }
    }
  `);

  return <img src={data.logo.publicURL} alt="wrrright" className={props.className} />
}

export default Logo
