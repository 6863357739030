import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      // light: '#E2DACD',
      main:'#b0a99c',
      // dark: '#817a6e',
      // contrastText: '#fafafa',
    },
    secondary: {
      main: '#e2dacd',
    }
  },
  typography: {
    fontFamily: "'Karla', 'Helvetica', 'Arial', sans-serif",
    fontSize: 18,
  },
  transitions: {
    easing: {
      bounce: "cubic-bezier(.21,1.11,.6,1.15)"
    },
    duration: {
      long: 500,
      longer: 750,
      longest: 1000,
    }
  },
  components: {
    navbar: {
      height: 80,
      attachOffset: 200,
      zIndex: 10,
    },
    image: {
      scale: {
        xs: .5,
        sm: .6,
        md: .8,
        lg: 1,
        xl: 1,
      },
      default: {
        width: 300,
        height: 400,
        gap: 30,
      },
      photographySeries: {
        height: 500,
        width: 500 * .75,
        gap: 40,
      }
    },
    shop: {
      gallery: {
        item: {
          size: 280,
        }
      },
      cart: {
        zIndex: 5,
      }
    }
  }
})
