/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from '@material-ui/core';
import { CssBaseline, Container } from '@material-ui/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ShopifyBuyProvider } from 'components/context/ShopifyBuy';
import ConditionalWrapper from 'components/utility/ConditionalWrapper';
import Nav from 'components/nav/nav';
import Footer from 'components/footer/footer';
import theme from 'components/theme';
import useStyles from './main.css';
import "../../styles/app.scss";
import "./global.css";

library.add(fab);

const Layout = ({ children, navbar, containerSize, navAttachSize, enableCart }) => {

  const classes = useStyles();

  return (
    <ShopifyBuyProvider
      domain={process.env.GATSBY_SHOPIFY_STORE_DOMAIN}
      accessToken={process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN}
    >
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <ConditionalWrapper
            condition={!!containerSize}
            wrapper={Container}
            wrapperProps={{
              maxWidth: containerSize,
              className: classes.fluid,
            }}
          >
            <main>{children}</main>
          </ConditionalWrapper>
          <Footer />
        </div>
        <Nav withBar={navbar} containerSize={navAttachSize || containerSize} enableCart={enableCart} />
      </ThemeProvider>
    </ShopifyBuyProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navbar: PropTypes.bool,
  containerSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  navAttachSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}

Layout.defaultProps = {
  navbar: false,
}

export default Layout;
