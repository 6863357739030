import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  const transition = `${theme.transitions.duration.standard}ms`;
  const navHeightFloating = theme.components.navbar.height * 0.7;
  const navHeightAttached = theme.components.navbar.height;

  function navAttachBreakpoint(containerSize){
    return `@media (min-width: ${theme.breakpoints.values[containerSize] + theme.components.navbar.attachOffset}px)`;
  }
  
  return {
    navRoot: ({ open, containerSize }) => ({
      height: navHeightFloating,
      padding: 0,
      zIndex: theme.components.navbar.zIndex,
      position: 'fixed',
      top: 20,
      left: 0,
      width: '100vw',
      fontSize: theme.typography.fontSize - 4 + 'px',
      pointerEvents: 'none',
      [navAttachBreakpoint(containerSize)]: {
        height: navHeightAttached,
        top: 30,
        display: 'block',
        fontSize: theme.typography.fontSize,
      }, 
    }),
    navBlur: ({ open }) => ({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: theme.palette.background.default,
      opacity: open ? 0.9 : 0,
      pointerEvents: open ? 'auto' : 'none',
      touchAction: open ? 'auto' : 'none',
      zIndex: 0,
      transition: '300ms',
    }),
    container: ({ containerSize }) => ({
      position: 'relative',
      minHeight: navHeightFloating,
      display: 'contents',
      [navAttachBreakpoint(containerSize)]: {
        minHeight: navHeightAttached,
        display: 'block',
      }
    }),
    nav: ({ containerSize }) => ({
      display: 'block',
      position: 'absolute',
      left: theme.spacing(2),
      top: 0,
      zIndex: 1,
      pointerEvents: 'auto',
      [navAttachBreakpoint(containerSize)]: {
        right: '100%',
        left: 'auto',
      },
      [`@media (min-width: 1600px)`]: {
        marginRight: '5rem',
      }
    }),
    cartAccess: ({ containerSize }) => ({
      display: 'block',
      position: 'fixed',
      right: theme.spacing(1),
      top: 0,
      padding: theme.spacing(1),
      zIndex: 1,
      pointerEvents: 'auto',
      [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        right: theme.spacing(2),
      },
      [navAttachBreakpoint(containerSize)]: {
        left: '100%',
        right: 'auto',
      },
      [`@media (min-width: 1600px)`]: {
        marginLeft: '5rem',
      }
    }),
    cartAccessButton: {
      position: 'relative',
      background: 'transparent',
      border: 'none',
      fontSize: 30,
      padding: '7px 8px 3px',
      borderRadius: '50%',
      // background: 'dodgerblue',
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    cartItemsCount: {
      position: 'absolute',
      top: 4,
      left: 21,
      backgroundColor: theme.palette.background.default,
      borderRadius: 3,
      fontSize: 14,
      margin: 0,
      padding: 1,
    },
    bar: ({ withBar, containerSize }) => ({
      display: withBar ? 'block' : 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: navHeightFloating,
      maxHeight: navHeightFloating,
      margin: 0,
      zIndex: -1,
      backgroundColor: theme.palette.background.default,
      pointerEvents: 'none',
      [navAttachBreakpoint(containerSize)]: {
        height: navHeightAttached,
        maxHeight: navHeightAttached,
      }
    }),
    brand: ({ open, containerSize }) => ({
      width: 'auto',
      height: navHeightFloating,
      opacity: open ? 1 : 0.15,
      cursor: 'pointer',
      pointerEvents: open ? 'auto' : 'none',
      touchAction: open ? 'auto' : 'none',
      transition: `opacity ${transition}`,
      overflow: 'visible',
      [navAttachBreakpoint(containerSize)]: {
        height: navHeightAttached,
        opacity: open ? 1 : 0.4,
      },
      '& img': {
        margin: 'auto',
        height: '100%',
        maxHeight: '100%',
        filter: `invert(${open ? 0 : .5})`,
        position: 'relative',
        zIndex: 1,
      },
    }),
    menu: ({ open, navSize }) => ({
      opacity: open ? 1 : 0.4,
      height: open ? `${navSize + 3}em` : 0,
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      transition: `opacity ${transition}, height ${transition}`,
    }),
  }
});