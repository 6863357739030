import React from 'react';
import { Link } from 'gatsby';
import { footerLinks } from '../nav/navLinks';
import useStyles from './footer.css';

const Footer = (props) => {

    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <nav className={classes.sitemap}>
                <ul>
                    <Link to="/" className={classes.sitemapLink}>
                        <li>Home</li>
                    </Link>
                    {footerLinks.map(link => (
                        <Link key={link.text} to={link.href} className={classes.sitemapLink}>
                            <li>{link.text}</li>
                        </Link>
                    ))}
                </ul>
            </nav>
            <hr className={classes.hr} />
            <div className={classes.copyright}>
                Copyright &copy; 2018-{new Date().getFullYear()} Ian Wright. All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;