import _ from 'lodash';
import React from 'react';
import Client from 'shopify-buy';

// remember checkout session in local storage
const checkoutSessionKey = 'shopifyCheckoutId';

// encapsulate context within this module, access via "use" hook or Provider
const ShopifyBuyContext = React.createContext({});

// Shopify Buy context hook
export const useShopifyBuy = () => {
    return React.useContext(ShopifyBuyContext);
}

// Provider Component to wrap Layout content
export function ShopifyBuyProvider({ domain, accessToken, children }) {

    // request pending state
    const [pending, setPending] = React.useState(true);
    // client state
    const [state, setState] = React.useState({
        client: null,               // shopify storefront client
        checkout: null,             // checkout/cart session
    });

    // [Config] Read checkout session id from cache
    const readSessionFromCache = () => {
        return localStorage.getItem(checkoutSessionKey);
    }
    // [Config] Write checkout session id to cache
    const writeSessionCache = (sessionId) => {
        localStorage.setItem(checkoutSessionKey, sessionId);
    }
    // [Config] Clear session id cache
    const clearSessionCache = () => {
        localStorage.removeItem(checkoutSessionKey);
    }

    // [Config] Initial connection to Shopify store
    const connectStore = async (domain, accessToken) => {
        try {
            const client = Client.buildClient({
                domain,
                storefrontAccessToken: accessToken,
            });
            const cachedSession = readSessionFromCache();
            let checkout;
            if (cachedSession) {
                checkout = await client.checkout.fetch(cachedSession);
            }
            if (!checkout || checkout.completedAt) {
                console.log('Creating new checkout session...');
                clearSessionCache();
                checkout = await client.checkout.create();
            }
            setState(state => ({ ...state, client, checkout }));
            writeSessionCache(checkout.id);
        } catch (err) {
            console.error("Shopify Buy connection failed with: ", err);
        } finally {
            setPending(false);
        }
    }

    // [Helper] API request wrapper
    const makeRequest = (name, callback) => async (...args) => {
        if (pending) {
            console.log('Request pending... not submitting request');
            return;
        }
        try {
            setPending(true);
            await callback(...args);
        } catch (err) {
            console.error(`${name}() failed with: `, err);
        } finally {
            setPending(false);
        }
    }

    // [API] Add to cart
    const addToCart = makeRequest('addToCart', (product, quantity) => {
        const variantId = product.variants[0].id;
        const lineItems = [{ variantId, quantity: parseInt(quantity, 10)}];
        return state.client.checkout
            .addLineItems(state.checkout.id, lineItems)
            .then(checkout => {
                // update checkout session state
                setState(state => ({ ...state, checkout }));
                writeSessionCache(checkout.id);
            });
    });

    // [API] Update cart quantity
    const updateQuantity = makeRequest('updateQuantity', (lineItemId, quantity) => {
        const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10)}];
        return state.client.checkout
            .updateLineItems(state.checkout.id, lineItemsToUpdate)
            .then(checkout => {
                console.log('session updated')
                // update checkout session state
                setState(state => ({ ...state, checkout }));
                writeSessionCache(checkout.id);
            })
    });

    // [API] Remove from cart
    const removeFromCart = makeRequest('removeFromCart', (lineItemId) => {
        return state.client.checkout
            .removeLineItems(state.checkout.id, [lineItemId])
            .then(checkout => {
                // update checkout session state
                setState(state => ({ ...state, checkout }));
                writeSessionCache(checkout.id);
            });
    });

    // reshape context payload (checkout not included)
    const context = {
        mounted: !!state.client,
        pending,
        client: state.client,
        checkout: state.checkout,
        cart: {
            add: addToCart,
            update: updateQuantity,
            remove: removeFromCart,
        }
    }

    // Configure Shopify Buy API connection on mount
    React.useEffect(() => {
        if (!domain || !accessToken) {
            console.warn('No shopify configuration', {
                domain, accessToken
            });
            return;
        }
        if (!context.mounted) {
            console.log(`Shopify Provider connecting to ${domain}...`);
            connectStore(domain, accessToken);
            console.log('Shopify Buy configuration complete.');
        } else {
            console.log('Shopify Buy already mounted.');
        }
    }, []);

    return (
        <ShopifyBuyContext.Provider value={context}>
            {children}
        </ShopifyBuyContext.Provider>
    );
}
