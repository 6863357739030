import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { useShopifyBuy } from 'components/context/ShopifyBuy';
import { shopIsOnline } from 'components/context/ShopStatus';
import Container from '@material-ui/core/Container';
import ConditionalWrapper from 'components/utility/ConditionalWrapper';
import CartIcon from 'components/shop/CartIcon';
import useStyles from './nav.css';
import Logo from '../logo';
import { navLinks } from '../nav/navLinks';

const Nav = ({ withBar, containerSize, enableCart = false }) => {

  const shopify = useShopifyBuy();
  const cartItems = _.get(shopify, 'checkout.lineItems', []);
  const showCart = shopIsOnline() && enableCart && cartItems.length > 0;

  const [state, setState] = React.useState({
    open: false,
    transitioning: false,
  });

  const openNav = React.useCallback((e) => {

    // keylistener check
    if (e.type === 'keyup' && e.keyCode !== 13) return;

    if (!state.open && !state.transitioning) {
      setState(st => ({
        ...st,
        open: true,
        transitioning: true,
      }))
    }
  }, [state.open, state.transitioning]);

  const closeNav = React.useCallback(() => {
    if (state.open && !state.transitioning) {
      setState(st => ({
        ...st,
        open: false,
        transitioning: true,
      }))
    }
  }, [state.open, state.transitioning])

  const transitionEnd = React.useCallback(() => {
    setState(st => ({
      ...st,
      transitioning: false,
    }))
  }, []);

  const navToRoot = () => {
    if (state.open && !state.transitioning) {
      closeNav();
      navigate('/#');
    }
  };

  const classes = useStyles({ open: state.open, withBar, containerSize, navSize: navLinks.length });

  return (
    <div id="nav-root" className={classes.navRoot}>
      <div id="nav-blur" className={classes.navBlur} onClick={closeNav} onMouseMove={closeNav} onTouchStart={closeNav} />
      <ConditionalWrapper
        condition={!!containerSize}
        wrapper={Container}
        wrapperProps={{
          maxWidth: containerSize,
          className: classes.container,
        }}
      >
          <nav className={classes.nav} onMouseMove={openNav} onMouseLeave={closeNav}>
            <figure className={classes.bar} />
            <div onClick={navToRoot} onTouchStart={openNav}>
              <Logo className={classes.brand}/>
            </div>
            <ul className={classes.menu} onTransitionEnd={transitionEnd}>
              {navLinks.map( (link, i) => (
                <Link key={i} to={link.href} onClick={closeNav}>
                  <li>{link.text}</li>
                </Link>
              ))}
            </ul>
          </nav>
          {showCart && (
            <div className={classes.cartAccess}>
              <Link to="/shop/cart">
                <button className={classes.cartAccessButton}>
                  <CartIcon />              
                  <figure className={classes.cartItemsCount}>
                    {cartItems.length}
                  </figure>
                </button>
              </Link>
            </div>
          )}
      </ConditionalWrapper>
    </div>
  );
}

Nav.propTypes = {
  withBar: PropTypes.bool,
  containerSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}

Nav.defaultProps = {
  withBar: false,
}

export default Nav;
